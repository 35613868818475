import PropTypes from "prop-types";
import * as React from "react";

import CarbonPicto from "../../images/categories/carbon.svg";
import GroupPicto from "../../images/categories/group.svg";
import HealthPicto from "../../images/categories/health.svg";
import InclusionPicto from "../../images/categories/inclusion.svg";
import MobilityPicto from "../../images/categories/mobility.svg";
import TechPicto from "../../images/categories/tech.svg";
import VisitePicto from "../../images/categories/visite.svg";
import InnovationPicto from "../../images/categories/innovation.svg";


export const getPicto = (type) => {
    switch (type) {
        case 1: return CarbonPicto;
        case 2: return CarbonPicto;
        case 3: return InnovationPicto;
        case 4: return HealthPicto;
        case 5: return InnovationPicto;
        case 6: return GroupPicto;
        case 7: return InclusionPicto;
        case 8: return MobilityPicto;
        case 9: return MobilityPicto;
        case 10: return HealthPicto;
        case 11: return MobilityPicto;
        default: return CarbonPicto;
    }
}

export const getName = (type) => {
    switch (type) {
        case 1: return "Construction bas carbone";
        case 2: return "Ville durable";
        case 3: return "Smart City";
        case 4: return "Vie responsable";
        case 5: return "Impact AI";
        case 6: return "Groupe";
        case 7: return "RH";

        case 8: return "Construction";
        case 9: return "Minorange";
        case 10: return "Vie / Travail";
        case 11: return "Sport";
        default: return "Environnement";
    }
}


const Category = (props) => {
    const { conf } = props;

    return (
        <div className="flex flex-row items-center mb-5">
            <img className="w-6 mb-1 mr-3" src={getPicto(conf.description)} alt={getName(conf.description)}/>
            <span className="font-bryant font-medium uppercase text-2xl tracking-cat">{getName(conf.description)}</span>
        </div>
    )
}

Category.propTypes = {
    conf: PropTypes.object.isRequired,
}



export default Category
